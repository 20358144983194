// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accent-connect-sales-enablement-tool-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/accent-connect-sales-enablement-tool.js" /* webpackChunkName: "component---src-pages-accent-connect-sales-enablement-tool-js" */),
  "component---src-pages-amazon-web-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/amazon-web-services.js" /* webpackChunkName: "component---src-pages-amazon-web-services-js" */),
  "component---src-pages-careers-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-craypay-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/craypay.js" /* webpackChunkName: "component---src-pages-craypay-js" */),
  "component---src-pages-current-openings-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/current-openings.js" /* webpackChunkName: "component---src-pages-current-openings-js" */),
  "component---src-pages-fih-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/fih.js" /* webpackChunkName: "component---src-pages-fih-js" */),
  "component---src-pages-fill-in-rx-pharmacy-staffing-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/fill-in-rx-pharmacy-staffing.js" /* webpackChunkName: "component---src-pages-fill-in-rx-pharmacy-staffing-js" */),
  "component---src-pages-fitcom-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/fitcom.js" /* webpackChunkName: "component---src-pages-fitcom-js" */),
  "component---src-pages-food-truck-spaces-technology-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/food-truck-spaces-technology.js" /* webpackChunkName: "component---src-pages-food-truck-spaces-technology-js" */),
  "component---src-pages-freewire-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/freewire.js" /* webpackChunkName: "component---src-pages-freewire-js" */),
  "component---src-pages-go-cabs-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/go-cabs.js" /* webpackChunkName: "component---src-pages-go-cabs-js" */),
  "component---src-pages-hire-dedicated-developers-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/hire/dedicated-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-developers-js" */),
  "component---src-pages-hire-dedicated-development-team-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/hire/dedicated-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-development-team-js" */),
  "component---src-pages-how-it-works-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-ihealth-monitoring-platform-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/ihealth-monitoring-platform.js" /* webpackChunkName: "component---src-pages-ihealth-monitoring-platform-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iresult-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/iresult.js" /* webpackChunkName: "component---src-pages-iresult-js" */),
  "component---src-pages-joybird-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/joybird.js" /* webpackChunkName: "component---src-pages-joybird-js" */),
  "component---src-pages-lp-android-app-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/android-app-development-company.js" /* webpackChunkName: "component---src-pages-lp-android-app-development-company-js" */),
  "component---src-pages-lp-android-app-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/android-app-development-services.js" /* webpackChunkName: "component---src-pages-lp-android-app-development-services-js" */),
  "component---src-pages-lp-application-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/application-development-company.js" /* webpackChunkName: "component---src-pages-lp-application-development-company-js" */),
  "component---src-pages-lp-application-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/application-development-services.js" /* webpackChunkName: "component---src-pages-lp-application-development-services-js" */),
  "component---src-pages-lp-custom-software-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/custom-software-development-company.js" /* webpackChunkName: "component---src-pages-lp-custom-software-development-company-js" */),
  "component---src-pages-lp-custom-software-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/custom-software-development-services.js" /* webpackChunkName: "component---src-pages-lp-custom-software-development-services-js" */),
  "component---src-pages-lp-ecommerce-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/ecommerce-development-company.js" /* webpackChunkName: "component---src-pages-lp-ecommerce-development-company-js" */),
  "component---src-pages-lp-ecommerce-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/ecommerce-development-services.js" /* webpackChunkName: "component---src-pages-lp-ecommerce-development-services-js" */),
  "component---src-pages-lp-ios-app-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/ios-app-development-company.js" /* webpackChunkName: "component---src-pages-lp-ios-app-development-company-js" */),
  "component---src-pages-lp-ios-app-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/ios-app-development-services.js" /* webpackChunkName: "component---src-pages-lp-ios-app-development-services-js" */),
  "component---src-pages-lp-magento-ecommerce-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/magento-ecommerce-development.js" /* webpackChunkName: "component---src-pages-lp-magento-ecommerce-development-js" */),
  "component---src-pages-lp-mobile-app-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/mobile-app-development-company.js" /* webpackChunkName: "component---src-pages-lp-mobile-app-development-company-js" */),
  "component---src-pages-lp-mobile-app-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/mobile-app-development-services.js" /* webpackChunkName: "component---src-pages-lp-mobile-app-development-services-js" */),
  "component---src-pages-lp-outsourcing-software-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/outsourcing-software-development-services.js" /* webpackChunkName: "component---src-pages-lp-outsourcing-software-development-services-js" */),
  "component---src-pages-lp-software-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/software-development-company.js" /* webpackChunkName: "component---src-pages-lp-software-development-company-js" */),
  "component---src-pages-lp-software-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/software-development-services.js" /* webpackChunkName: "component---src-pages-lp-software-development-services-js" */),
  "component---src-pages-lp-software-testing-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/software-testing-company.js" /* webpackChunkName: "component---src-pages-lp-software-testing-company-js" */),
  "component---src-pages-lp-software-testing-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/software-testing-services.js" /* webpackChunkName: "component---src-pages-lp-software-testing-services-js" */),
  "component---src-pages-lp-web-app-development-company-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/web-app-development-company.js" /* webpackChunkName: "component---src-pages-lp-web-app-development-company-js" */),
  "component---src-pages-lp-web-app-development-services-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/lp/web-app-development-services.js" /* webpackChunkName: "component---src-pages-lp-web-app-development-services-js" */),
  "component---src-pages-mission-rabies-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/mission-rabies.js" /* webpackChunkName: "component---src-pages-mission-rabies-js" */),
  "component---src-pages-ona-dating-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/ona-dating.js" /* webpackChunkName: "component---src-pages-ona-dating-js" */),
  "component---src-pages-privacy-policy-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-redbull-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/redbull.js" /* webpackChunkName: "component---src-pages-redbull-js" */),
  "component---src-pages-roundr-real-estate-technology-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/roundr-real-estate-technology.js" /* webpackChunkName: "component---src-pages-roundr-real-estate-technology-js" */),
  "component---src-pages-sentiment-market-analytics-engine-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/sentiment-market-analytics-engine.js" /* webpackChunkName: "component---src-pages-sentiment-market-analytics-engine-js" */),
  "component---src-pages-services-api-integration-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/api-integration-development.js" /* webpackChunkName: "component---src-pages-services-api-integration-development-js" */),
  "component---src-pages-services-application-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/application-development.js" /* webpackChunkName: "component---src-pages-services-application-development-js" */),
  "component---src-pages-services-aws-application-firewall-and-security-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/aws-application-firewall-and-security.js" /* webpackChunkName: "component---src-pages-services-aws-application-firewall-and-security-js" */),
  "component---src-pages-services-aws-cloud-management-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/aws-cloud-management.js" /* webpackChunkName: "component---src-pages-services-aws-cloud-management-js" */),
  "component---src-pages-services-aws-data-engineering-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/aws-data-engineering.js" /* webpackChunkName: "component---src-pages-services-aws-data-engineering-js" */),
  "component---src-pages-services-aws-secure-resilient-solutions-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/aws-secure-resilient-solutions.js" /* webpackChunkName: "component---src-pages-services-aws-secure-resilient-solutions-js" */),
  "component---src-pages-services-aws-serverless-and-orchestration-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/aws-serverless-and-orchestration.js" /* webpackChunkName: "component---src-pages-services-aws-serverless-and-orchestration-js" */),
  "component---src-pages-services-cloud-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/cloud-development.js" /* webpackChunkName: "component---src-pages-services-cloud-development-js" */),
  "component---src-pages-services-cloud-native-engineering-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/cloud-native-engineering.js" /* webpackChunkName: "component---src-pages-services-cloud-native-engineering-js" */),
  "component---src-pages-services-custom-software-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/custom-software-development.js" /* webpackChunkName: "component---src-pages-services-custom-software-development-js" */),
  "component---src-pages-services-devops-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/devops.js" /* webpackChunkName: "component---src-pages-services-devops-js" */),
  "component---src-pages-services-digital-product-engineering-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/digital-product-engineering.js" /* webpackChunkName: "component---src-pages-services-digital-product-engineering-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-software-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-services-software-product-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/software-product-development.js" /* webpackChunkName: "component---src-pages-services-software-product-development-js" */),
  "component---src-pages-services-software-testing-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/software-testing.js" /* webpackChunkName: "component---src-pages-services-software-testing-js" */),
  "component---src-pages-services-talent-solutions-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/talent-solutions.js" /* webpackChunkName: "component---src-pages-services-talent-solutions-js" */),
  "component---src-pages-services-technology-practices-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/technology-practices.js" /* webpackChunkName: "component---src-pages-services-technology-practices-js" */),
  "component---src-pages-services-web-apps-development-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/services/web-apps-development.js" /* webpackChunkName: "component---src-pages-services-web-apps-development-js" */),
  "component---src-pages-spark-studio-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/spark-studio.js" /* webpackChunkName: "component---src-pages-spark-studio-js" */),
  "component---src-pages-stubbs-and-woottons-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/stubbs-and-woottons.js" /* webpackChunkName: "component---src-pages-stubbs-and-woottons-js" */),
  "component---src-pages-swift-shopper-retail-checkout-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/swift-shopper-retail-checkout.js" /* webpackChunkName: "component---src-pages-swift-shopper-retail-checkout-js" */),
  "component---src-pages-swift-shopper-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/swift-shopper.js" /* webpackChunkName: "component---src-pages-swift-shopper-js" */),
  "component---src-pages-thank-you-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-trans-tms-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/trans-tms.js" /* webpackChunkName: "component---src-pages-trans-tms-js" */),
  "component---src-pages-tryg-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/tryg.js" /* webpackChunkName: "component---src-pages-tryg-js" */),
  "component---src-pages-virtual-interior-designing-systems-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/virtual-interior-designing-systems.js" /* webpackChunkName: "component---src-pages-virtual-interior-designing-systems-js" */),
  "component---src-pages-visibly-vision-prescription-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/visibly-vision-prescription.js" /* webpackChunkName: "component---src-pages-visibly-vision-prescription-js" */),
  "component---src-pages-way-point-engagement-technology-js": () => import("/codebuild/output/src457733018/src/bitbucket.org/simformteam/simform-website-redesign-2019/src/pages/way-point-engagement-technology.js" /* webpackChunkName: "component---src-pages-way-point-engagement-technology-js" */)
}

